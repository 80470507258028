import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MENUITEMS } from '../app.routes';
import { MenuItem } from '../models/menu-item';

export const noNeedLoginGuard: CanActivateFn = (route, state) => {
  const _authService = inject(AuthService);
  const _router = inject(Router);
  if (!_authService.isAuth) {
    return true;
  } else {
    const menuItems = MENUITEMS;
    const permissions = _authService.permissions;
    const firstAvailableMenu = menuItems.find((item: MenuItem) => {
      return item.permissions?.find((permission) =>
        permissions.includes(permission)
      );
    });
    if (firstAvailableMenu) {
      _router.navigate([firstAvailableMenu.route]);
      return false;
    } else {
      return true;
    }
  }
};
