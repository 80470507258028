import { Component } from '@angular/core';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-available-soon',
  standalone: true,
  imports: [BreadcrumbComponent, TranslateModule],
  templateUrl: './available-soon.component.html',
  styleUrl: './available-soon.component.scss',
})
export class AvailableSoonComponent {}
