<div class="page-container full-h">
  <div class="container-fluid g-0">
    <div class="d-flex flex-row">
      <app-breadcrumb />
    </div>
  </div>
  <div class="fill-container">
    <img
      src="assets/images/under_construction.svg"
      alt=""
      class="construction"
    />
    <h1>{{ "available_soon" | translate }}</h1>
  </div>
</div>
